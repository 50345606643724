var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CreateDialog',{attrs:{"dialog":_vm.createDialog},on:{"close":function($event){_vm.createDialog = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.pageTitle()))])]},proxy:true},{key:"body",fn:function(){return [_c('v-sheet',{staticStyle:{"height":"calc(100vh - 188px)"}},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[(_vm.createDialog)?_c('v-form',{ref:"milestoneForm"},[_c('v-row',[_c('v-col',{attrs:{"md":"9"}},[_c('perfect-scrollbar',{staticClass:"scroll custom-box-top-inner-shadow",staticStyle:{"position":"relative"},attrs:{"options":{ suppressScrollX: true }}},[_c('v-row',{staticClass:"px-4"},[_c('v-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"btx-label mt-2",attrs:{"for":"name"}},[_vm._v("Name")])]),_c('v-col',{attrs:{"md":"9"}},[_c('TextInput',{attrs:{"hide-details":"","placeholder":"Name","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"name"},model:{value:(_vm.milestone.phase),callback:function ($$v) {_vm.$set(_vm.milestone, "phase", $$v)},expression:"milestone.phase"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"btx-label mt-5",attrs:{"for":"description"}},[_vm._v("Description")])]),_c('v-col',{attrs:{"md":"9"}},[_c('TextAreaInput',{attrs:{"disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"description","placeholder":"Description"},model:{value:(_vm.milestone.description),callback:function ($$v) {_vm.$set(_vm.milestone, "description", $$v)},expression:"milestone.description"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"btx-label mt-4 required",attrs:{"for":"date"}},[_vm._v("Date")])]),_c('v-col',{attrs:{"md":"3"}},[_c('DatePicker',{class:{
													required: !_vm.milestone.date,
												},attrs:{"hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"date","rules":[_vm.vrules.required(_vm.milestone.date, 'Date')]},model:{value:(_vm.milestone.date),callback:function ($$v) {_vm.$set(_vm.milestone, "date", $$v)},expression:"milestone.date"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"md":"3"}},[_c('label',{staticClass:"btx-label mt-4",attrs:{"for":"reminder_date"}},[_vm._v("Reminder Date")])]),_c('v-col',{attrs:{"md":"3"}},[_c('DatePicker',{attrs:{"hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"reminder_date"},model:{value:(_vm.milestone.reminder_date),callback:function ($$v) {_vm.$set(_vm.milestone, "reminder_date", $$v)},expression:"milestone.reminder_date"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"btx-label mt-4",attrs:{"for":"type"}},[_vm._v("Amount Type")])]),_c('v-col',{attrs:{"md":"3"}},[_c('AutoCompleteInput',{attrs:{"hide-details":"","items":_vm.amountTypes,"placeholder":"Payment Type","disabled":_vm.pageLoading,"id":"type","loading":_vm.pageLoading},model:{value:(_vm.payment_method),callback:function ($$v) {_vm.payment_method=$$v},expression:"payment_method"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"md":"3"}},[(_vm.payment_method === 1)?_c('label',{staticClass:"btx-label mt-4 required",attrs:{"for":"payment_amount"}},[_vm._v("Milestone Amount")]):_c('label',{staticClass:"btx-label mt-4 required",attrs:{"for":"payment_amount"}},[_vm._v("Percentage")])]),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"md":"3"}},[(_vm.payment_method === 1)?_c('PriceInput',{class:{
													required: !_vm.milestone.payment_amount,
												},attrs:{"hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"payment_amount","type":"number","placeholder":"Amount","rules":[_vm.vrules.required(_vm.milestone.payment_amount, 'Payment Amount')]},on:{"keyup":_vm.changeMilestoneAmount},model:{value:(_vm.milestone.payment_amount),callback:function ($$v) {_vm.$set(_vm.milestone, "payment_amount", _vm._n($$v))},expression:"milestone.payment_amount"}}):_c('TextInput',{class:{ required: !_vm.milestone.percentage },attrs:{"hide-details":"","id":"payment_amount","placeholder":"Percentage","vMask":"###","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"rules":[
													_vm.vrules.required(_vm.milestone.percentage, 'Percentage'),
													_vm.vrules.lessThan(_vm.milestone.percentage, 'Percentage', 100),
												]},model:{value:(_vm.milestone.percentage),callback:function ($$v) {_vm.$set(_vm.milestone, "percentage", $$v)},expression:"milestone.percentage"}}),_c('div',{staticClass:"ml-2 mt-4 font-level-0 d-flex justify-center align-center"},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-currency-usd")]),_c('span',[_vm._v(_vm._s(_vm.projectDetail?.project_balance))]),_c('TooltipQuestion',{scopedSlots:_vm._u([{key:"text",fn:function(){return [_vm._v("Total Budget of project")]},proxy:true}],null,false,549857467)})],1)],1),_c('v-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"btx-label mt-4",attrs:{"for":"attachment"}},[_vm._v("Attachment")])]),_c('v-col',{attrs:{"md":"9"}},[_c('FileUpload',{attrs:{"id":"attachment"},model:{value:(_vm.milestone.attachment),callback:function ($$v) {_vm.$set(_vm.milestone, "attachment", $$v)},expression:"milestone.attachment"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"btx-label mt-4",attrs:{"for":"quotation"}},[_vm._v("Quotations")])]),_c('v-col',{attrs:{"md":"9"}},[_c('AutoCompleteInput',{attrs:{"multiple":"","hide-details":"","items":_vm.quotationList,"disabled":_vm.pageLoading,"item-text":"tileBarcode","item-value":"id","loading":_vm.pageLoading,"id":"quotation","placeholder":"quotations"},model:{value:(_vm.milestone.quotations),callback:function ($$v) {_vm.$set(_vm.milestone, "quotations", $$v)},expression:"milestone.quotations"}})],1)],1)],1)],1)],1)],1):_vm._e()],1)],1)],1)]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{attrs:{"depressed":"","tile":"","disabled":_vm.pageLoading},on:{"click":function($event){(_vm.createDialog = false), _vm.$router.go(-1)}}},[_vm._v(" Close ")]),_c('v-btn',{staticClass:"white--text",attrs:{"depressed":"","color":"blue darken-4","tile":""},on:{"click":_vm.updateOrCreate}},[_vm._v(" Save ")])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }