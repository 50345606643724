<template>
	<CreateDialog :dialog="createDialog" v-on:close="createDialog = false">
		<template v-slot:title>
			<span>{{ pageTitle() }}</span>
		</template>
		<template v-slot:body>
			<v-sheet style="height: calc(100vh - 188px)">
				<v-row>
					<v-col md="12">
						<v-form v-if="createDialog" ref="milestoneForm">
							<v-row>
								<v-col md="9">
									<perfect-scrollbar
										:options="{ suppressScrollX: true }"
										class="scroll custom-box-top-inner-shadow"
										style="position: relative"
									>
										<v-row class="px-4">
											<v-col md="3">
												<label for="name" class="btx-label mt-2">Name</label>
											</v-col>
											<v-col md="9">
												<TextInput
													hide-details
													placeholder="Name"
													v-model="milestone.phase"
													:disabled="pageLoading"
													:loading="pageLoading"
													id="name"
												></TextInput>
											</v-col>
											<v-col md="3">
												<label for="description" class="btx-label mt-5">Description</label>
											</v-col>
											<v-col md="9">
												<TextAreaInput
													:disabled="pageLoading"
													:loading="pageLoading"
													id="description"
													placeholder="Description"
													v-model="milestone.description"
												>
												</TextAreaInput>
											</v-col>
											<v-col md="3">
												<label for="date" class="btx-label mt-4 required">Date</label>
											</v-col>
											<v-col md="3">
												<DatePicker
													hide-details
													:disabled="pageLoading"
													:loading="pageLoading"
													id="date"
													v-model="milestone.date"
													:rules="[vrules.required(milestone.date, 'Date')]"
													:class="{
														required: !milestone.date,
													}"
												></DatePicker>
											</v-col>
											<v-col class="text-right" md="3">
												<label for="reminder_date" class="btx-label mt-4">Reminder Date</label>
											</v-col>
											<v-col md="3">
												<DatePicker
													hide-details
													:disabled="pageLoading"
													:loading="pageLoading"
													id="reminder_date"
													v-model="milestone.reminder_date"
												></DatePicker>
											</v-col>
											<v-col class="" md="3">
												<label for="type" class="btx-label mt-4">Amount Type</label>
											</v-col>
											<v-col md="3">
												<AutoCompleteInput
													hide-details
													:items="amountTypes"
													placeholder="Payment Type"
													v-model="payment_method"
													:disabled="pageLoading"
													id="type"
													:loading="pageLoading"
												>
												</AutoCompleteInput>
											</v-col>
											<v-col class="text-right" md="3">
												<label v-if="payment_method === 1" for="payment_amount" class="btx-label mt-4 required"
													>Milestone Amount</label
												>
												<label v-else for="payment_amount" class="btx-label mt-4 required">Percentage</label>
											</v-col>
											<v-col md="3" class="d-flex justify-center align-center">
												<PriceInput
													v-if="payment_method === 1"
													hide-details
													:disabled="pageLoading"
													:loading="pageLoading"
													id="payment_amount"
													type="number"
													placeholder="Amount"
													:rules="[vrules.required(milestone.payment_amount, 'Payment Amount')]"
													:class="{
														required: !milestone.payment_amount,
													}"
													v-model.number="milestone.payment_amount"
													@keyup="changeMilestoneAmount"
												>
												</PriceInput>
												<TextInput
													v-else
													hide-details
													id="payment_amount"
													placeholder="Percentage"
													vMask="###"
													v-model="milestone.percentage"
													:disabled="pageLoading"
													:loading="pageLoading"
													:rules="[
														vrules.required(milestone.percentage, 'Percentage'),
														vrules.lessThan(milestone.percentage, 'Percentage', 100),
													]"
													:class="{ required: !milestone.percentage }"
												>
													<!-- <template v-slot:append>
														<inline-svg :src="$assetURL('media/custom-svg/percent-outline.svg')" />
													</template> -->
												</TextInput>
												<div class="ml-2 mt-4 font-level-0 d-flex justify-center align-center">
													<v-icon x-small>mdi-currency-usd</v-icon>
													<span>{{ projectDetail?.project_balance }}</span>
													<TooltipQuestion>
														<template v-slot:text>Total Budget of project</template>
													</TooltipQuestion>
												</div>
											</v-col>
											<v-col md="3">
												<label for="attachment" class="btx-label mt-4">Attachment</label>
											</v-col>
											<v-col md="9">
												<FileUpload id="attachment" v-model="milestone.attachment"></FileUpload>
											</v-col>
											<v-col md="3"> <label for="quotation" class="btx-label mt-4">Quotations</label> </v-col>
											<v-col md="9">
												<AutoCompleteInput
													multiple
													hide-details
													:items="quotationList"
													:disabled="pageLoading"
													item-text="tileBarcode"
													item-value="id"
													:loading="pageLoading"
													id="quotation"
													placeholder="quotations"
													v-model="milestone.quotations"
												>
												</AutoCompleteInput>
											</v-col>
											<!-- <v-col md="3">
												<label for="payment_remaining" class="btx-label mt-4">Remaining</label>
											</v-col>
											<v-col md="9">
												<PriceInput hide-details :disabled="pageLoading" :loading="pageLoading"
													id="payment_remaining" type="number" placeholder="Payment Remaining"
													v-model.number="milestone.payment_remaining">
												</PriceInput>
											</v-col> -->
										</v-row>
									</perfect-scrollbar>
								</v-col>
							</v-row>
						</v-form>
					</v-col>
				</v-row>
			</v-sheet>
		</template>
		<template v-slot:action>
			<v-btn
				depressed
				tile
				:disabled="pageLoading"
				v-on:click="(createDialog = false), $router.go(-1)"
			>
				Close
			</v-btn>
			<v-btn class="white--text" depressed color="blue darken-4" tile @click="updateOrCreate">
				Save
			</v-btn>
		</template>
	</CreateDialog>
</template>
<script>
import { mapGetters } from "vuex";
import TextInput from "@/view/components/TextInput";
import PriceInput from "@/view/components/PriceInput";
import FileUpload from "@/view/components/FileUpload";
import TextAreaInput from "@/view/components/TextAreaInput";
import DatePicker from "@/view/components/DatePicker";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import CreateDialog from "@/view/components/CreateDialog";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import {
	updateMilestone,
	getQuotationAndPurchaseOrder,
	CreateMilestone,
	getMilestoneByUUID,
} from "@/core/lib/project.lib";
import { GetProject } from "@/core/lib/project.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";

export default {
	name: "create-milestone",
	title: "Milestone-create",
	components: {
		// Dialog,
		PriceInput,
		TextInput,
		FileUpload,
		AutoCompleteInput,
		CreateDialog,
		DatePicker,
		TextAreaInput,
		TooltipQuestion,
	},
	props: {
		addDialog: {
			type: Boolean,
			default: false,
		},
		project: {
			type: Object,
			default: () => {},
		},
		milestoneDetail: {
			ype: Object,
			default: () => {},
		},
		milestoneUUID: {
			type: String,
		},
	},
	data() {
		return {
			pageLoading: false,
			uuid: null,
			createDialog: true,
			quotationList: [],
			payment_method: 1,
			amountTypes: [],
			projectDetail: null,
			milestone: {
				date: null,
				phase: null,
				attachment: [],
				percentage: null,
				description: null,
				invoice_date: null,
				reminder_date: null,
				quotations: [],
				amount_raised: null,
				payment_amount: null,
				invoice_raised: null,
				payment_received: null,
				// payment_remaining: null,
			},
		};
	},
	computed: {
		...mapGetters(["errors"]),
	},
	methods: {
		async updateOrCreate() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.milestoneForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.milestoneForm.validate()) {
				return false;
			}
			try {
				const formData = {
					project: _this.projectDetail.id,
					phase: _this.milestone.phase,
					date: _this.milestone.date,
					description: _this.milestone.description,
					amount: _this.milestone.payment_amount,
					progress: _this.milestone.percentage,
					quotations: _this.milestone.quotations,
					reminder_date: _this.milestone.reminder_date,
					files: _this.milestone.attachment,
					amount_raised: _this.milestone.amount_raised,
					invoice_date: _this.milestone.invoice_date,
					invoice_raised: _this.milestone.invoice_raised,
					payment_received: _this.milestone.payment_received,
					// remaining: _this.milestone.payment_remaining,
				};
				_this.pageLoading = true;
				if (_this.uuid) {
					const data = await updateMilestone(_this.$route.query.mileUUID, formData);
					_this.$emit("success", data);
					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Milestone has been update." },
					]);
				} else {
					const data = await CreateMilestone(formData);
					_this.$emit("success", data);
					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Milestone has been create." },
					]);
				}

				_this.uuid = null;
				_this.milestone = {
					project: null,
					phase: null,
					date: null,
					description: null,
					amount: null,
					progress: null,
					// quotation: null,
					reminder_date: null,
					files: null,
					amount_raised: null,
					invoice_raised: null,
					payment_received: null,
					remaining: null,
					quotations: [],
				};
				_this.goBack();
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		pageTitle() {
			if (this.$route.query.mileUUID) {
				return "Edit Milestone";
			}
			return "Create Milestone";
		},
		async getQuotationAndPO(id) {
			try {
				const data = await getQuotationAndPurchaseOrder(id);
				this.quotationList = [...data.quotations];
				this.quotationList = this.quotationList.map((quotation) => {
					if (quotation.project === this.projectDetail.id) {
						quotation.tileBarcode = quotation.barcode + "-" + quotation.title.toUpperCase();
						return quotation;
					}
				});
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
		async getProject() {
			try {
				// this.pageLoading = true;
				const project = await GetProject(this.$route.params.uuid);
				this.projectDetail = project[0];
				await this.getQuotationAndPO(this.projectDetail.id);
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
		async getMilestone() {
			try {
				this.pageLoading = true;
				const milestone = await getMilestoneByUUID(this.$route.query.mileUUID);
				return milestone[0];
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
		milestoneAmountChange() {
			if (this.milestone.payment_amount > this.projectDetail.cost) {
				this.milestone.payment_amount = this.projectDetail.cost;
			}
		},
		changeMilestoneAmount() {
			if (this.milestone?.payment_amount > this.projectDetail?.project_balance) {
				this.milestone.payment_amount = this.projectDetail?.project_balance;
			}
		},
	},
	async mounted() {
		await this.$store.dispatch(SET_LOCAL_DB).then((data) => {
			this.amountTypes = data.amount_types;
		});
		await this.getProject();
		if (this.$route.query?.mileUUID) {
			this.uuid = this.$route.query?.mileUUID;
			const milestone = await this.getMilestone();
			const quotation = milestone.quotations.map((ele) => {
				return ele.id;
			});
			milestone.quotations = quotation;
			this.milestone = {
				date: milestone.date,
				phase: milestone.phase,
				payment_amount: milestone.amount,
				percentage: milestone.progress ? milestone.progress : null,
				description: milestone.description,
				invoice_date: milestone.invoice_date,
				reminder_date: milestone.reminder_date,
				// amount_raised: milestone.amount_raised ? milestone.amount_raised : null,
				invoice_raised: milestone.invoice_raised ? milestone.invoice_raised : null,
				quotations: milestone.quotations ? milestone.quotations : null,
				payment_received: milestone.payment_received ? milestone.payment_received : null,
				// payment_remaining: milestone.remaining ? milestone.remaining : null,
			};
		}
	},
};
</script>
